<template>
  <v-container fluid>
    <v-layout row wrap class="mt-4">
      <v-card flat class="my-0">
        <v-layout wrap class="pt-5 pb-5">
          <v-flex>
            <v-layout row align-center justify-center wrap fill-height>
              <v-card
                color="white"
                height="80"
                width="340"
                style="
                  border-radius: 10px;
                  margin-right: 20px;
                  margin-top: 20px;
                "
              >
                <v-layout
                  column
                  wrap
                  fill-height
                  align-start
                  justify-start
                  align-content-start
                >
                  <v-card
                    color="purple darken-3"
                    height="80"
                    width="70"
                    style="border-radius: 10px 0 0 10px"
                  >
                    <v-layout align-center justify-center row fill-height>
                      <v-icon color="white" size="50">groups</v-icon>
                    </v-layout>
                  </v-card>
                  <v-card
                    flat
                    max-width="240"
                    width="240"
                    max-height="80"
                    height="80"
                  >
                    <div class="ml-3" style="font-size: 2.2em">
                      <b>
                        {{ getDataofExamStatusfn.data.total_examinee }}
                      </b>
                      <br />
                      <h6 id="titel_en">TOTAL EXAMINEE</h6>
                    </div>
                  </v-card>
                </v-layout>
              </v-card>

              <v-card
                color="white"
                height="80"
                width="340"
                style="
                  border-radius: 10px;
                  margin-right: 20px;
                  margin-top: 20px;
                "
              >
                <v-layout
                  column
                  wrap
                  fill-height
                  align-start
                  justify-start
                  align-content-start
                >
                  <v-card
                    color="#146064"
                    height="80"
                    width="70"
                    style="border-radius: 10px 0 0 10px"
                  >
                    <v-layout align-center justify-center row fill-height>
                      <v-icon color="white" size="50">school</v-icon>
                    </v-layout>
                  </v-card>
                  <v-card
                    flat
                    max-width="240"
                    width="240"
                    max-height="80"
                    height="80"
                  >
                    <div class="ml-3" style="font-size: 2.2em">
                      <b>
                        {{ getDataofExamStatusfn.data.total_college }}
                      </b>
                      <br />
                      <h6 id="titel_en">TOTAL COLLEGE</h6>
                    </div>
                  </v-card>
                </v-layout>
              </v-card>

              <v-card
                color="white"
                height="80"
                width="340"
                style="
                  border-radius: 10px;
                  margin-right: 20px;
                  margin-top: 20px;
                "
              >
                <v-layout
                  column
                  wrap
                  fill-height
                  align-start
                  justify-start
                  align-content-start
                >
                  <v-card
                    color="#1b5e20"
                    height="80"
                    width="70"
                    style="border-radius: 10px 0 0 10px"
                  >
                    <v-layout align-center justify-center row fill-height>
                      <v-icon color="white" size="50">account_balance</v-icon>
                    </v-layout>
                  </v-card>
                  <v-card
                    flat
                    max-width="240"
                    width="240"
                    max-height="80"
                    height="80"
                  >
                    <div class="ml-3" style="font-size: 2.2em">
                      <b>
                        {{ getDataofExamStatusfn.data.total_center }}
                      </b>
                      <br />
                      <h6 id="titel_en">TOTAL CENTER</h6>
                    </div>
                  </v-card>
                </v-layout>
              </v-card>

              <v-card
                color="white"
                height="80"
                width="340"
                style="
                  border-radius: 10px;
                  margin-right: 20px;
                  margin-top: 20px;
                "
              >
                <v-layout
                  column
                  wrap
                  fill-height
                  align-start
                  justify-start
                  align-content-start
                >
                  <v-card
                    color="#0D47A1"
                    height="80"
                    width="70"
                    style="border-radius: 10px 0 0 10px"
                  >
                    <v-layout align-center justify-center row fill-height>
                      <v-icon color="white" size="50">book</v-icon>
                    </v-layout>
                  </v-card>
                  <v-card
                    flat
                    max-width="200"
                    width="240"
                    max-height="80"
                    height="80"
                  >
                    <div class="ml-3" style="font-size: 2.2em">
                      <b>
                        {{ getDataofExamStatusfn.data.total_subject }}
                      </b>
                      <br />
                      <h6 id="titel_en">TOTAL SUBJECTS</h6>
                    </div>
                  </v-card>
                </v-layout>
              </v-card>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-layout>
    <v-layout row wrap class="mt-4">
      <span>
        <div class="display-1 mb-4">
          <v-icon size="50" color="primary">dashboard</v-icon>
          <span class="ml-2">Exam Status</span>
        </div>
      </span>

       <v-card
          style="width:100%; ovrtflow:auto;"
          color="white ml-10 "
          v-if="getDataofExamStatusfn.data.activities"
        >
          <v-card color="white ml-4 pl-4" flat style="padding-left:10%;">
            <v-timeline dense clipped align-top class="ma-0 pt-4">
              <v-timeline-item
                v-for="(data, index) in getDataofExamStatusfn.data.activities"
                :key="index"
                :color="getStatusColor(data)"
                large
              >
                <template v-slot:icon>
                  <v-icon color="white">account_balance</v-icon>
                </template>

                <v-card class="elevation-4 mr-5" :color="getStatusColor(data)">
                  <v-layout column>
                    <v-toolbar class="mb-1" flat :color="getStatusColor(data)">
                      <v-btn style="pointer-events: none; color:#fff;" flat class="title">{{
                        data.activity_name
                      }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-toolbar-items></v-toolbar-items>
                      <v-btn
                        style="pointer-events: none"
                        flat
                        small
                        v-if="data.start_date"
                        class="title"
                      >
                        <v-chip
                          small
                          style="margin-top: -2em; margin-right: -1.2em"
                          >Start</v-chip
                        >
                        <v-chip
                          label
                          class="subheading font-weight-medium"
                          style="z-index: 10"
                          >{{ getFormatedDate(data.start_date) }}</v-chip
                        >
                      </v-btn>
                      <v-btn
                        style="pointer-events: none"
                        class="title"
                        flat
                        small
                        v-if="checkIfEndDate(data)"
                      >
                        <v-chip
                          label
                          class="subheading font-weight-medium"
                          style="z-index: 10"
                          >{{ getFormatedDate(data.end_date) }}</v-chip
                        >
                        <v-chip
                          small
                          style="margin-top: -2em; margin-left: -1.2em"
                          >End</v-chip
                        >
                      </v-btn>
                    </v-toolbar>
                  </v-layout>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-card>
        <v-card v-else>
          <v-alert :value="true" color="error" icon="warning" outline
            >No Data To Show</v-alert
          >
        </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import commonExamYear from "../../../mixin/exam_year_watch_mixin.vue";
import commonGet from "../../../mixin/common_getters_mixin";
import ICountUp from "vue-countup-v2";
import * as moment from "moment";
export default {
  mixins: [commonGet, commonExamYear],
  components: { ICountUp },
  data: () => ({
    date: "27th February 2020",
    dateDialog: false,
    searchParam: {},
  }),
  created() {
    this.getAllStatusofExamfn();
  },
  mounted() {},
  watch: {},

  computed: {
    getDataofExamStatusfn() {
      return this.$store.getters.getDataofExamStatus;
    },
  },
  methods: {
    getAllStatusofExamfn() {
      let tada = this.getExamInitiateId;
      this.$store
        .dispatch("getAllStatusofExam", { exam_initiate_id: tada })
        .then(() => {
          console.log("tanvir----vue-----> 200", this.getExamInitiateId);
        });
    },

    getFormatedDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    checkIfEndDate(date) {
      if (date.activity_name === "Exam Initiate") {
        return false;
      }
      return date.end_date;
    },
    getStatusColor(data) {
      var date = moment(data.end_date, "YYYY-MM-DD");
      var chamar = moment(data.start_date, "YYYY-MM-DD");
      var now = moment();
        if(data.activity_name == "Exam Initiate"){
            return "blue";
        }
        else{
            if(chamar > now){
                return "blue";
            }else if(date < now){
                return "red accent-2";
            }
        }
        return "blue";
    },
    getStatusColorLogo(date) {
      var date = moment(data.end_date, "YYYY-MM-DD");
      var chamar = moment(data.start_date, "YYYY-MM-DD");
      var now = moment();
        if(data.activity_name == "Exam Initiate"){
            return "blue";
        }
        else{
            if(chamar > now){
                return "blue";
            }else if(date < now){
                return "red darken-4";
            }
        }
        return "white";

    //   if (data.start_date) {
    //       if (now > date) {
    //         return "red";
    //       } else {
    //         return "blue ";
    //       }
    //     } else {
    //       return "black";
    //     }
    },
  },
};
</script>

<style lang="scss" scoped>
.iCountUp {
  font-size: 3em;
}
.examtimeline {
  .theme--light.v-btn.v-btn--disabled {
    color: rgba(0, 0, 0, 1) !important;
  }
  .theme--light.v-btn.v-btn--disabled .v-icon {
    color: rgba(0, 0, 0, 1) !important;
  }
  .customExamTimeLineCss {
    margin-top: -0.7em;
    // margin-right: 2em;
  }
}
</style>
